import { Injectable } from '@angular/core';
import { AbstractConfigService, EnvironmentVariables } from '@af-web/shared';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FvConfigService extends AbstractConfigService {
  get baseUrl(): string {
    return environment.baseUrl;
  }

  get environment(): EnvironmentVariables {
    return environment;
  }

}
